












import {Component, Prop, Vue} from "vue-property-decorator";
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
/*import Multiselect from '@vueform/multiselect'*/

@Component({
name: "MultiselectSearch",
components:{ Multiselect }
})
export default class MultiselectSearch extends Vue {
  protected value = [];

  @Prop({default: []})
  protected options!: any;

}
